import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css"; // You can choose other themes
import axios from "axios";

const ResponsiveAgGridTable = () => {
  const uid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);

  useEffect(() => {
    getTableData();
  }, []);

  const getTableData = async () => {
    try {
      const res = await axios.get(
        `http://103.96.222.133/justtawk-uae/index.php/api/Onboarding/CallLogs/${uid}`,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Handle the response data structure (e.g., res.data.data)
      const data = res?.data?.data || [];
      setRowData(data);

      if (data.length > 0) {
        const dynamicColumnDefs = Object.keys(data[0]).map((key) => ({
          headerName: key.replace(/([A-Z])/g, " $1").toUpperCase(), // Convert camelCase to Title Case
          field: key,
          sortable: true,
          filter: true,
          resizable: true,
        }));
        setColumnDefs(dynamicColumnDefs);
      }
      console.log("API Data:", data); // For debugging
    } catch (error) {
      console.error("Error fetching table data:", error);
    }
  };

  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
    minWidth: 100,
  };

  return (
    <div style={{ width: "100%", height: "600px" }}>
      <div
        className="ag-theme-alpine"
        style={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <AgGridReact
          columnDefs={columnDefs} // Use dynamically generated columnDefs
          rowData={rowData} // Set row data from API
          defaultColDef={defaultColDef}
          domLayout="autoHeight" // Adjusts the height automatically based on content
          pagination={true}
          paginationPageSize={10}
        />
      </div>
    </div>
  );
};

export default ResponsiveAgGridTable;
