import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Grid,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { hover } from "@testing-library/user-event/dist/hover";

const pricingPlans = [
  {
    title: "Demo",
    price: "Free",
    features: [
      "Super user account",
      "Unlimited events",
      "Registration Form",
      "Email announcements",
      "Integrate webhooks",
      "Sales using mobile app",
    ],
  },
  {
    title: "Business",
    price: 250,
    features: [
      "All Team features",
      "Advanced analytics",
      "Priority support",
      "Custom branding",
      "API access",
      "Dedicated account manager",
    ],
  },
  {
    title: "Enterprise",
    price: 400,
    features: [
      "All Business features",
      "Unlimited users",
      "Tailored solutions",
      "Enhanced security",
      "Onboarding support",
      "Personalized training",
    ],
  },
];

export default function TeamPricingCard() {
  return (
    <Box sx={{ p: 3, mt: 2, width: "100%" }}>
      <Grid container spacing={3} justifyContent="center">
        {/* Demo Plan */}
        <Grid item xs={12} sm={6} md={3.5}>
          <Card
            sx={{
              maxWidth: 400,
              width: "100%",
              position: "relative",
              overflow: "visible",
              transition: "transform 0.3s ease-in-out", // Add a smooth transition
              "&:hover": {
                transform: "scale(1.05)", // Scale up by 5% when hovered
              },
            }}
          >
            <CardContent sx={{ p: 5 }}>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  textAlign: "center",
                  fontWeight: 500,
                  mb: 2,
                }}
              >
                {pricingPlans[0].title}
              </Typography>

              <Box sx={{ textAlign: "center", mb: 3 }}>
                <Typography
                  variant="h3"
                  component="div"
                  sx={{
                    fontWeight: "bold",
                    display: "inline-flex",
                    alignItems: "baseline",
                  }}
                >
                  {pricingPlans[0].price}
                </Typography>
              </Box>

              <Button
                variant="contained"
                fullWidth
                sx={{
                  mb: 2,
                  py: 1.5,
                  textTransform: "none",
                  borderRadius: 2,
                  backgroundColor: "#1a237e",
                  "&:hover": {
                    backgroundColor: "#0d47a1",
                  },
                }}
              >
                Get started
              </Button>

              <List sx={{ mt: 1 }}>
                {pricingPlans[0].features.map((feature, idx) => (
                  <ListItem key={idx} sx={{ px: 0, py: 1 }}>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <CheckIcon sx={{ color: "#1a237e" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={feature}
                      sx={{
                        "& .MuiListItemText-primary": {
                          fontSize: "0.95rem",
                          color: "text.secondary",
                        },
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>

        {/* Business Plan */}
        <Grid item xs={12} sm={6} md={3.5}>
          <Card
            sx={{
              maxWidth: 400,
              width: "100%",
              position: "relative",
              overflow: "visible",
              transition: "transform 0.3s ease-in-out", // Add a smooth transition
              "&:hover": {
                transform: "scale(1.05)", // Scale up by 5% when hovered
              },
            }}
          >
            <CardContent sx={{ p: 5 }}>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  textAlign: "center",
                  fontWeight: 500,
                  mb: 2,
                }}
              >
                {pricingPlans[1].title}
              </Typography>

              <Box sx={{ textAlign: "center", mb: 3 }}>
                <Typography
                  variant="h3"
                  component="div"
                  sx={{
                    fontWeight: "bold",
                    display: "inline-flex",
                    alignItems: "baseline",
                  }}
                >
                  ${pricingPlans[1].price}
                  <Typography
                    component="span"
                    sx={{
                      fontSize: "1rem",
                      ml: 1,
                      color: "text.secondary",
                    }}
                  >
                    /month
                  </Typography>
                </Typography>
              </Box>

              <Button
                variant="contained"
                fullWidth
                sx={{
                  mb: 2,
                  py: 1.5,
                  textTransform: "none",
                  borderRadius: 2,
                  backgroundColor: "#1a237e",
                  "&:hover": {
                    backgroundColor: "#0d47a1",
                  },
                }}
              >
                Get started
              </Button>

              <List sx={{ mt: 1 }}>
                {pricingPlans[1].features.map((feature, idx) => (
                  <ListItem key={idx} sx={{ px: 0, py: 1 }}>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <CheckIcon sx={{ color: "#1a237e" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={feature}
                      sx={{
                        "& .MuiListItemText-primary": {
                          fontSize: "0.95rem",
                          color: "text.secondary",
                        },
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>

        {/* Enterprise Plan */}
        <Grid item xs={12} sm={6} md={3.5}>
          <Card
            sx={{
              maxWidth: 400,
              width: "100%",
              position: "relative",
              overflow: "visible",
              transition: "transform 0.3s ease-in-out", // Add a smooth transition
              "&:hover": {
                transform: "scale(1.05)", // Scale up by 5% when hovered
              },
            }}
          >
            <CardContent sx={{ p: 5 }}>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  textAlign: "center",
                  fontWeight: 500,
                  mb: 2,
                }}
              >
                {pricingPlans[2].title}
              </Typography>

              <Box sx={{ textAlign: "center", mb: 3 }}>
                <Typography
                  variant="h3"
                  component="div"
                  sx={{
                    fontWeight: "bold",
                    display: "inline-flex",
                    alignItems: "baseline",
                  }}
                >
                  ${pricingPlans[2].price}
                  <Typography
                    component="span"
                    sx={{
                      fontSize: "1rem",
                      ml: 1,
                      color: "text.secondary",
                    }}
                  >
                    /month
                  </Typography>
                </Typography>
              </Box>

              <Button
                variant="contained"
                fullWidth
                sx={{
                  mb: 2,
                  py: 1.5,
                  textTransform: "none",
                  borderRadius: 2,
                  backgroundColor: "#1a237e",
                  "&:hover": {
                    backgroundColor: "#0d47a1",
                  },
                }}
              >
                Get started
              </Button>

              <List sx={{ mt: 1 }}>
                {pricingPlans[2].features.map((feature, idx) => (
                  <ListItem key={idx} sx={{ px: 0, py: 1 }}>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <CheckIcon sx={{ color: "#1a237e" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={feature}
                      sx={{
                        "& .MuiListItemText-primary": {
                          fontSize: "0.95rem",
                          color: "text.secondary",
                        },
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
