import React from "react";
import { Box, Typography, Grid, Card } from "@mui/material";
import SideNav from "../../components/sideNav";
import ReactEcharts from "echarts-for-react";
import CardsWidgets from "./CardsWidgets";

// Define chart options
const getBarChartOptions = () => ({
  title: { text: "Bar Chart Example" },
  tooltip: {},
  xAxis: { data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"] },
  yAxis: {},
  series: [{ type: "bar", data: [5, 20, 36, 10, 10, 20, 15] }],
});

const getPieChartOptions = () => ({
  title: { text: "Pie Chart Example", left: "" },
  tooltip: { trigger: "item" },
  series: [
    {
      type: "pie",
      radius: "50%",
      data: [
        { value: 1048, name: "Search" },
        { value: 735, name: "Direct" },
        { value: 580, name: "Email" },
        { value: 484, name: "Affiliate" },
        { value: 300, name: "Other" },
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
});

const getLineChartOptions = () => ({
  title: { text: "Line Chart Example" },
  tooltip: { trigger: "axis" },
  xAxis: {
    type: "category",
    data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  },
  yAxis: { type: "value" },
  series: [{ type: "line", data: [150, 230, 224, 218, 135, 147, 260] }],
});

function DashBoard() {
  return (
    <Box
      sx={{
        pt: {
          xs: "50px",
          md: "50px",
        },
        bgcolor: "#F4F6FE",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <SideNav />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Typography
            sx={{
              fontSize: "1.8rem",
              fontWeight: 900,
              color: "#424242",
            }}
            gutterBottom
          >
            Dashboard
          </Typography>
          <Box
            sx={{
              height: "0.5px",
              backgroundColor: "#c9c9c9",
            }}
          ></Box>
          <CardsWidgets />
          {/* <Grid container spacing={3} mt={2}>
            <Grid item xs={12} md={4}>
              <Card sx={{ p: 2 }}>
                <ReactEcharts
                  option={getBarChartOptions()}
                  style={{ height: 300 }}
                />
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card sx={{ p: 2 }}>
                <ReactEcharts
                  option={getPieChartOptions()}
                  style={{ height: 300 }}
                />
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card sx={{ p: 2 }}>
                <ReactEcharts
                  option={getLineChartOptions()}
                  style={{ height: 300 }}
                />
              </Card>
            </Grid>
          </Grid> */}
        </Box>
      </Box>
    </Box>
  );
}

export default DashBoard;
