import {
  Box,
  Button,
  Container,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
  Typography,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Logo from "../../../assets/logo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Number = () => {
  const navigate = useNavigate();
  const [phoneDetails, setPhoneDetails] = useState([]);
  const uuid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");

  const getPhoneNumber = async (data) => {
    try {
      const response = await axios.get(
        `http://103.96.222.133/justtawk-uae/index.php/api/Onboarding/FetchNumber`
      );
      setPhoneDetails(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const addPhoneNumber = async (data) => {
    const access_token = token;

    try {
      const response = await axios.put(
        `http://103.96.222.133/justtawk-uae/index.php/api/Onboarding/CustomerCareNumber/${uuid}`,
        data,
        {
          headers: {
            Authorization: `${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if ((response.status = true)) {
        navigate("/loading");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPhoneNumber();
  }, []);

  const formik = useFormik({
    initialValues: {
      selectedNumber: "", // Default selected value for radio button
    },
    validationSchema: Yup.object({
      selectedNumber: Yup.string().required("Please select a phone number"),
    }),
    onSubmit: (values) => {
      const data = {
        customerCareNo: values.selectedNumber,
      };
      console.log(data);
      addPhoneNumber(data);
      // alert(`Selected Number: ${values.selectedNumber}`);
    },
  });

  // Custom onChange handler
  const handleChange = (e) => {
    const { value, name } = e.target;

    const selectedPhone = phoneDetails.find((phone) => phone.did_id === value);
    console.log(selectedPhone);

    if (selectedPhone) {
      localStorage.setItem("selectedPhone", selectedPhone.did_alias);
    }

    formik.setFieldValue(name, value);
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Grid container>
        <Grid
          item
          md={9.5}
          xs={12}
          sx={{
            height: {
              xs: "100%",
              md: "100vh",
            },
          }}
        >
          <Box
            sx={{
              height: "7vh",
            }}
          >
            <img src={Logo} alt="Logo" height="100%" width="150" />
          </Box>
          <Container>
            <Box
              sx={{
                padding: {
                  xs: "40px 0px 0 0px",
                  md: "50px 0px 0 100px",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: "2rem",
                    md: "2.5rem",
                  },
                  fontWeight: 900,
                }}
              >
                Select your Customer Care Number
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: "1.2rem",
                    md: "1.4rem",
                  },
                  fontWeight: 10,
                  lineHeight: {
                    md: "25px",
                  },
                  color: "#6D6D6D",
                  mt: "15px",
                }}
              >
                Start getting calls by just selecting your call center number
              </Typography>
            </Box>

            <Grid
              container
              sx={{
                mt: {
                  xs: "50px",
                  md: "20px",
                },
                padding: {
                  xs: "0 0 0 0px",
                  md: "0 0 0 100px",
                },
              }}
            >
              <Grid item xs={12} md={12}>
                <Box>
                  <Box>
                    <FormLabel
                      component="legend"
                      sx={{
                        fontSize: "0.8rem",
                        fontWeight: 10,
                        pb: "10px",
                      }}
                    >
                      We found 9 best number for your business
                    </FormLabel>
                    <form onSubmit={formik.handleSubmit}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="number"
                          name="selectedNumber"
                          value={formik.values.selectedNumber}
                          onChange={handleChange} // Use the custom handleChange
                        >
                          {phoneDetails.map((number) => (
                            <FormControlLabel
                              key={number?.did_id}
                              value={number?.did_id} // This is the actual value that gets submitted
                              control={<Radio />}
                              label={number?.did_alias} // This is what the user sees
                            />
                          ))}
                        </RadioGroup>
                        {formik.touched.selectedNumber &&
                        formik.errors.selectedNumber ? (
                          <Typography color="error">
                            {formik.errors.selectedNumber}
                          </Typography>
                        ) : null}
                      </FormControl>

                      <Grid
                        item
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          //   mt: {
                          //     xs: "40px",
                          //     md: "-10px",
                          //   },
                          //   ml: {
                          //     xs: "0px",
                          //     md: "30px",
                          //   },
                          p: {
                            xs: "0 0 0px 0",
                            md: "0 0 10px 0",
                          },
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          sx={{
                            whiteSpace: "nowrap",
                            textTransform: "capitalize",
                            mt: "2px",
                          }}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </form>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid
          item
          xs={12}
          md={2.5}
          sx={{
            background: "#1A5CB0",
            padding: "0 0px 50px 0",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.2rem",
              color: "white",
              fontWeight: 900,
              p: "100px 20px 40px 20px",
            }}
          >
            Quick & Easy Setup
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stepper
              activeStep={3}
              orientation="vertical"
              sx={{
                color: "white",
                "& .MuiStepLabel-label": {
                  color: "white",
                },
              }}
            >
              <Step>
                <StepLabel>
                  <Typography
                    sx={{
                      color: "#6198DE",
                      fontSize: "0.8rem",
                      ml: 1,
                      mb: 0, // Remove bottom margin
                    }}
                  >
                    Business Details
                  </Typography>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  <Typography
                    sx={{
                      color: "#6198DE",
                      fontSize: "0.8rem",
                      ml: 1,
                      mb: 0, // Remove bottom margin
                    }}
                  >
                    Define Frequently Ask Questions
                  </Typography>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "0.8rem",
                      ml: 1,
                      mb: 0, // Remove bottom margin
                    }}
                  >
                    Select Customer Care Number
                  </Typography>
                </StepLabel>
              </Step>
            </Stepper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Number;
