import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import Logo from "../../../../assets/logo.png";
import { useSpring, animated } from "react-spring";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import NorthIcon from "@mui/icons-material/North";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import TopArrow from "../../../../assets/Group.png";
import axios from "axios";
import ECommerceMobile from "./ECommerceMobile";

const validationSchema = Yup.object({
  question1: Yup.mixed().required("field is required"), // Making companyName required
  question2: Yup.mixed().required("field is required"), // Making industryType required
  question3: Yup.mixed().required("field is required"), // Making name required
  question4: Yup.mixed().required("field is required"), // Making name required
  question5: Yup.mixed().required("field is required"), // Making name required
});

const steps = [
  {
    title: "FAQ",
    description: "What are your delivery timelines?",
    placeholder: "Enter OTP",
  },
  {
    title: "FAQ",
    description: "How do I track my order?",
    placeholder: "Enter OTP",
  },
  {
    title: "FAQ",
    description: "Do you offer gift-wrapping services?",
    placeholder: "Enter OTP",
  },
  {
    title: "FAQ",
    description: "Can I change my delivery address after placing an order?",
    placeholder: "Enter OTP",
  },
  {
    title: "FAQ",
    description: "Do you offer cash on delivery (COD)?",
    placeholder: "Enter OTP",
  },
];

const ECommerce = ({ nextStep }) => {
  const navigate = useNavigate();
  const sections = useRef([]);
  const [currentStep, setCurrentStep] = React.useState(0);
  const [activeStep, setActiveStep] = React.useState(0);

  const scrollToSection = (index) => {
    sections.current[index]?.scrollIntoView({ behavior: "smooth" });
  };

  const uuid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");

  const addFaqDetails = async (data) => {
    const access_token = token;

    try {
      const response = await axios.post(
        `http://103.96.222.133/justtawk-uae/index.php/api/Onboarding/Faq/${uuid}`,
        data,
        {
          headers: {
            Authorization: `${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      navigate("/number");
      console.log("Response:", response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const formik = useFormik({
    initialValues: {
      question1: "",
      question2: "",
      question3: "",
      question4: "",
      question5: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      addFaqDetails(values);
    },
  });

  const handleNext = async () => {
    const stepFields = {
      0: ["question1"],
      1: ["question2"],
      2: ["question3"],
      3: ["question4"],
      4: ["question5"],
    };

    const currentFields = stepFields[currentStep];

    formik.setTouched(
      currentFields.reduce((acc, field) => ({ ...acc, [field]: true }), {})
    );

    const errors = await formik.validateForm();

    const hasErrors = currentFields.some((field) => errors[field]);

    if (!hasErrors) {
      if (currentStep < steps.length - 1) {
        setCurrentStep((prev) => {
          const nextStep = prev + 1;
          scrollToSection(nextStep);
          return nextStep;
        });
        setActiveStep((prev) => prev + 1);
      } else {
        formik.handleSubmit();
      }
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => {
        const prevStep = prev - 1;
        scrollToSection(prevStep);
        setActiveStep((prev) => prev - 1);
        return prevStep;
      });
    }
  };

  const fade = useSpring({ opacity: 1, from: { opacity: 0 } });

  return (
    <Box>
      <Box
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        <Grid container>
          <Grid
            item
            md={9.5}
            xs={12}
            sx={{
              height: {
                xs: "100%",
                md: "100vh",
              },
            }}
          >
            <Box
              sx={{
                height: "7vh",
              }}
            >
              <img src={Logo} alt="Logo" height="100%" width="150" />{" "}
            </Box>
            <Container maxWidth={"md"}>
              <Box
                sx={{
                  padding: {
                    xs: "40px 0px 0 0px",
                    md: "50px 0px 0 0px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "2rem",
                      md: "2.5rem",
                    },
                    fontWeight: 900,
                  }}
                >
                  FAQ's
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "1.2rem",
                      md: "1.4rem",
                    },
                    fontWeight: 10,
                    lineHeight: {
                      md: "25px",
                    },
                    color: "#6D6D6D",
                    mt: "15px",
                  }}
                >
                  E-commerce (Online Retail Store)
                </Typography>
              </Box>

              <form onSubmit={formik.handleSubmit}>
                <Grid
                  container
                  sx={{
                    mt: {
                      xs: "20px",
                      md: "100px",
                    },
                    display: {
                      xs: "none",
                      md: "flex",
                    },
                  }}
                >
                  <Grid item xs={12} md={9.5} sx={{}}>
                    <Box>
                      <Box
                        sx={{
                          height: {
                            xs: "30vh",
                            md: "40vh",
                          },
                          overflow: "hidden",
                          // paddingBottom: "50px",
                        }}
                      >
                        <Box
                          sx={{
                            width: {
                              xs: "100%",
                              md: "100%",
                              p: "00px 0 100px 0",
                            },
                          }}
                        >
                          <animated.div style={fade}>
                            {/* Step 1 */}
                            <div ref={(el) => (sections.current[0] = el)}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color:
                                      currentStep !== 0 ? "lightgrey" : "black",
                                    opacity: currentStep !== 0 ? 0.5 : 1,
                                  }}
                                >
                                  What are your delivery timelines?
                                </Typography>

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        formik.values.question1 ===
                                        "We offer a 30-day free trial."
                                      }
                                      onChange={(e) => {
                                        const checked = e.target.checked;
                                        formik.setFieldValue(
                                          "question1",
                                          checked
                                            ? "We offer a 30-day free trial."
                                            : ""
                                        );
                                      }}
                                      disabled={currentStep !== 0}
                                    />
                                  }
                                  label="Suggestion"
                                />
                              </Box>

                              <TextField
                                name="question1"
                                id="question1"
                                variant="filled"
                                margin="normal"
                                fullWidth
                                rows={3}
                                multiline
                                value={formik.values.question1}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                disabled={currentStep !== 0}
                                error={
                                  formik.touched.question1 &&
                                  Boolean(formik.errors.question1)
                                }
                                helperText={
                                  formik.touched.question1 &&
                                  formik.errors.question1
                                    ? formik.errors.question1
                                    : ""
                                }
                                sx={{
                                  opacity: currentStep !== 0 ? 0.3 : 1,
                                }}
                              />
                            </div>

                            {/* Step 2 */}
                            <div
                              ref={(el) => (sections.current[1] = el)}
                              style={{ marginTop: "40px" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color:
                                      currentStep !== 1 ? "lightgrey" : "black",
                                    opacity: currentStep !== 1 ? 0.5 : 1,
                                  }}
                                >
                                  How do I track my order?
                                </Typography>

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        formik.values.question2 ===
                                        "To upgrade your plan, please visit the settings page."
                                      }
                                      onChange={(e) => {
                                        const checked = e.target.checked;
                                        formik.setFieldValue(
                                          "question2",
                                          checked
                                            ? "To upgrade your plan, please visit the settings page."
                                            : ""
                                        );
                                      }}
                                      disabled={currentStep !== 1}
                                    />
                                  }
                                  label="Suggestion"
                                />
                              </Box>
                              <TextField
                                sx={{ opacity: currentStep !== 1 ? 0.3 : 1 }}
                                name="question2"
                                id="question2"
                                value={formik.values.question2}
                                onChange={formik.handleChange}
                                fullWidth
                                rows={3}
                                multiline
                                variant="filled"
                                margin="normal"
                                disabled={currentStep !== 1}
                                error={
                                  formik.touched.question2 &&
                                  Boolean(formik.errors.question2)
                                }
                                helperText={
                                  formik.touched.question2 &&
                                  formik.errors.question2
                                    ? formik.errors.question2
                                    : ""
                                }
                              />
                            </div>

                            {/* Step 3 */}
                            <div
                              ref={(el) => (sections.current[2] = el)}
                              style={{ marginTop: "40px" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color:
                                      currentStep !== 2 ? "lightgrey" : "black",
                                    opacity: currentStep !== 2 ? 0.5 : 1,
                                  }}
                                >
                                  Do you offer gift-wrapping services?
                                </Typography>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        formik.values.question3 ===
                                        "We accept credit cards, PayPal, and bank transfers."
                                      }
                                      onChange={(e) => {
                                        const checked = e.target.checked;
                                        formik.setFieldValue(
                                          "question3",
                                          checked
                                            ? "We accept credit cards, PayPal, and bank transfers."
                                            : ""
                                        );
                                      }}
                                      disabled={currentStep !== 2}
                                    />
                                  }
                                  label="Suggestion"
                                />
                              </Box>

                              <TextField
                                sx={{ opacity: currentStep !== 2 ? 0.3 : 1 }}
                                id="question3"
                                name="question3"
                                value={formik.values.question3}
                                onChange={formik.handleChange}
                                fullWidth
                                rows={3}
                                multiline
                                variant="filled"
                                margin="normal"
                                disabled={currentStep !== 2}
                                error={
                                  formik.touched.question3 &&
                                  Boolean(formik.errors.question3)
                                }
                                helperText={
                                  formik.touched.question3 &&
                                  formik.errors.question3
                                    ? formik.errors.question3
                                    : ""
                                }
                              />
                            </div>

                            {/* Step 4 */}
                            <div
                              ref={(el) => (sections.current[3] = el)}
                              style={{ marginTop: "40px" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color:
                                      currentStep !== 3 ? "lightgrey" : "black",
                                  }}
                                >
                                  Can I change my delivery address after placing
                                  an order?s
                                </Typography>

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        formik.values.question4 ===
                                        "Yes, we provide support during the trial period."
                                      }
                                      onChange={(e) => {
                                        const checked = e.target.checked;
                                        formik.setFieldValue(
                                          "question4",
                                          checked
                                            ? "Yes, we provide support during the trial period."
                                            : ""
                                        );
                                      }}
                                      disabled={currentStep !== 3}
                                    />
                                  }
                                  label="Suggestion"
                                />
                              </Box>
                              <TextField
                                id="question4"
                                name="question4"
                                value={formik.values.question4}
                                onChange={formik.handleChange}
                                fullWidth
                                rows={3}
                                multiline
                                variant="filled"
                                margin="normal"
                                disabled={currentStep !== 3}
                                error={
                                  formik.touched.question4 &&
                                  Boolean(formik.errors.question4)
                                }
                                helperText={
                                  formik.touched.question4 &&
                                  formik.errors.question4
                                    ? formik.errors.question4
                                    : ""
                                }
                              />
                            </div>

                            {/* Step 5 */}
                            <div
                              ref={(el) => (sections.current[4] = el)}
                              style={{ marginTop: "40px" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color:
                                      currentStep !== 4 ? "lightgrey" : "black",
                                  }}
                                >
                                  Do you offer cash on delivery (COD)?
                                </Typography>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        formik.values.question5 ===
                                        "Yes, we provide comprehensive support during the trial period."
                                      }
                                      onChange={(e) => {
                                        const checked = e.target.checked;
                                        formik.setFieldValue(
                                          "question5",
                                          checked
                                            ? "Yes, we provide comprehensive support during the trial period."
                                            : ""
                                        );
                                      }}
                                      disabled={currentStep !== 4}
                                    />
                                  }
                                  label="Suggestion"
                                />
                              </Box>
                              <TextField
                                id="question5"
                                name="question5"
                                value={formik.values.question5}
                                onChange={formik.handleChange}
                                fullWidth
                                rows={3}
                                multiline
                                variant="filled"
                                margin="normal"
                                disabled={currentStep !== 4}
                                error={
                                  formik.touched.question5 &&
                                  Boolean(formik.errors.question5)
                                }
                                helperText={
                                  formik.touched.question5 &&
                                  formik.errors.question5
                                    ? formik.errors.question5
                                    : ""
                                }
                              />
                            </div>
                            <div
                              ref={(el) => (sections.current[5] = el)}
                              style={{ opacity: 0 }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color:
                                      currentStep !== 4 ? "lightgrey" : "black",
                                  }}
                                >
                                  Do you offer cash on delivery (COD)?
                                </Typography>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        formik.values.question5 ===
                                        "Yes, we provide comprehensive support during the trial period."
                                      }
                                      onChange={(e) => {
                                        const checked = e.target.checked;
                                        formik.setFieldValue(
                                          "question5",
                                          checked
                                            ? "Yes, we provide comprehensive support during the trial period."
                                            : ""
                                        );
                                      }}
                                      disabled={currentStep !== 4}
                                    />
                                  }
                                  label="Suggestion"
                                />
                              </Box>
                              <TextField
                                id="question5"
                                name="question5"
                                value={formik.values.question5}
                                onChange={formik.handleChange}
                                fullWidth
                                rows={3}
                                multiline
                                variant="filled"
                                margin="normal"
                                disabled={currentStep !== 4}
                                error={
                                  formik.touched.question5 &&
                                  Boolean(formik.errors.question5)
                                }
                                helperText={
                                  formik.touched.question5 &&
                                  formik.errors.question5
                                    ? formik.errors.question5
                                    : ""
                                }
                              />
                            </div>
                          </animated.div>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={2}
                    sx={{
                      ml: {
                        xs: "0px",
                        md: "30px",
                      },
                      p: {
                        xs: "20px 0 50px 0",
                        md: "0 0 0px 0",
                      },
                      display: {
                        xs: "flex",
                        md: "initial",
                      },
                      // justifyContent: "space-between",
                    }}
                  >
                    <Box
                      color="primary"
                      onClick={handleBack}
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      <img src={TopArrow} />
                    </Box>
                    <Button
                      variant="contained"
                      type="submit"
                      onClick={() => {
                        handleNext();
                      }}
                      sx={{
                        whiteSpace: "nowrap",
                        textTransform: "capitalize",
                        mt: {
                          xs: "0px",
                          md: "15px",
                        },
                        ml: {
                          xs: "30px",
                          md: "0",
                        },
                      }}
                    >
                      Save & go next
                    </Button>
                  </Grid>
                </Grid>
              </form>
              <Box
                sx={{
                  display: {
                    xs: "initial",
                    md: "none",
                  },
                }}
              >
                <ECommerceMobile />
              </Box>
            </Container>
          </Grid>
          <Grid
            item
            xs={12}
            md={2.5}
            sx={{
              background: "#1A5CB0",
              padding: "0 0px 50px 0",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.2rem",
                color: "white",
                fontWeight: 900,
                p: "100px 20px 40px 20px",
              }}
            >
              Quick & Easy Setup
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stepper
                activeStep={1}
                orientation="vertical"
                sx={{
                  color: "white",
                  "& .MuiStepLabel-label": {
                    color: "white",
                  },
                }}
              >
                <Step>
                  <StepLabel>
                    <Typography
                      sx={{
                        color: "#6198DE",
                        fontSize: "0.8rem",
                        ml: 1,
                        mb: 0,
                      }}
                    >
                      Business Details
                    </Typography>
                  </StepLabel>
                </Step>
                <Step>
                  <StepLabel>
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "0.8rem",
                        ml: 1,
                        mb: 0,
                      }}
                    >
                      Define Frequently Ask Questions
                    </Typography>
                  </StepLabel>
                </Step>
                <Step>
                  <StepLabel>
                    <Typography
                      sx={{
                        color: "#6198DE",
                        fontSize: "0.8rem",
                        ml: 1,
                        mb: 0,
                      }}
                    >
                      Select Customer Care Number
                    </Typography>
                  </StepLabel>
                </Step>
              </Stepper>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ECommerce;
