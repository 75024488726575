import {
  Autocomplete,
  Box,
  Button,
  Container,
  Grid,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import Logo from "../../../assets/logo.png";
import { useSpring, animated } from "react-spring";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import NorthIcon from "@mui/icons-material/North";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import TopArrow from "../../../assets/Group.png";
import axios from "axios";

const validationSchema = Yup.object({
  // countryName: Yup.mixed().required("Country name is required"), // Making countryName required
  companyName: Yup.mixed().required("Company name is required"), // Making companyName required
  industryType: Yup.mixed().required("Industry type is required"), // Making industryType required
  name: Yup.mixed().required("Name is required"), // Making name required
});

const industryOptions = [
  { label: "SaaS / Software Services", value: "saas" },
  { label: "Healthcare", value: "healthcare" },
  { label: "Education", value: "education" },
  { label: "Ecommerce", value: "ecommerce" },
  { label: "Start-Up", value: "startup" },
  { label: "Travel", value: "travel" },
  { label: "Telecommunications", value: "telecommunications" },
];

const steps = [
  {
    title: "Verify Your Account",
    description:
      "Share your business details to ensure our agents provide accurate and professional responses to your customers.",
    placeholder: "Enter countryName",
  },
  {
    title: "Setting up your call center",
    description:
      "Share your business details to ensure our agents provide accurate and professional responses to your customers.",
    placeholder: "Enter company name",
  },
  {
    title: "Base Setup",
    description:
      "Share your business details to ensure our agents provide accurate and professional responses to your customers.",
    placeholder: "Select industry type",
  },
];

const MultiStepForm = ({ nextStep }) => {
  const navigate = useNavigate();
  const sections = useRef([]);
  const [currentStep, setCurrentStep] = React.useState(0);
  const [activeStep, setActiveStep] = React.useState(0);
  const [disable, setDisable] = useState(false);

  const scrollToSection = (index) => {
    sections.current[index]?.scrollIntoView({ behavior: "smooth" });
  };
  const uuid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");

  const formik = useFormik({
    initialValues: {
      companyName: "",
      industryType: "",
      name: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("values");
      addFormData(values);
    },
  });

  const addFormData = async (data) => {
    const access_token = token;
    try {
      const response = await axios.put(
        `http://103.96.222.133/justtawk-uae/index.php/api/Onboarding/Register/${uuid}`,
        data,
        {
          headers: {
            Authorization: `${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const industryType = formik.values.industryType;

      if (industryType === "healthcare") {
        navigate("/healthCare");
      } else if (industryType === "saas") {
        navigate("/saas");
      } else if (industryType === "ecommerce") {
        navigate("/ECommerce");
      } else if (industryType === "education") {
        navigate("/Education");
      } else if (industryType === "startup") {
        navigate("/Startup");
      } else if (industryType === "telecommunications") {
        navigate("/Telecommunications");
      } else if (industryType === "travel") {
        navigate("/Travel");
      } else {
        console.warn("Unknown industry type:", industryType);
      }
      console.log("Response:", response.data);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleNext = async () => {
    const stepFields = {
      0: ["name"],
      1: ["companyName"],
      2: ["industryType"],
      3: ["dummy"],
      4: ["dummy"],
    };

    const currentFields = stepFields[currentStep];

    formik.setTouched(
      currentFields.reduce((acc, field) => ({ ...acc, [field]: true }), {})
    );
    const errors = await formik.validateForm();
    const hasErrors = currentFields.some((field) => errors[field]);
    if (!hasErrors) {
      if (currentStep < steps.length - 1) {
        setCurrentStep((prev) => {
          const nextStep = prev + 1;
          scrollToSection(nextStep);
          return nextStep;
        });
        setActiveStep((prev) => prev + 1);
      }
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => {
        const prevStep = prev - 1;
        scrollToSection(prevStep);
        setActiveStep((prev) => prev - 1);
        return prevStep;
      });
    }
  };

  const fade = useSpring({ opacity: 1, from: { opacity: 0 } });

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Grid container>
        <Grid
          item
          md={9.5}
          xs={12}
          sx={{
            height: {
              xs: "100%",
              md: "100vh",
            },
          }}
        >
          <Box
            sx={{
              height: "7vh",
            }}
          >
            <img src={Logo} alt="Logo" height="100%" width="150" />{" "}
          </Box>
          <Container maxWidth={"md"}>
            <Box
              sx={{
                padding: {
                  xs: "40px 0px 0 0px",
                  md: "50px 0px 0 0px",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: "2rem",
                    md: "2.5rem",
                  },
                  fontWeight: 900,
                }}
              >
                {steps[currentStep].title}
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: "1.2rem",
                    md: "1.4rem",
                  },
                  fontWeight: 10,
                  lineHeight: {
                    md: "25px",
                  },
                  color: "#6D6D6D",
                  mt: "15px",
                }}
              >
                {steps[currentStep].description}
              </Typography>
            </Box>

            <form onSubmit={formik.handleSubmit}>
              <Grid
                container
                sx={{
                  mt: {
                    xs: "20px",
                    md: "100px",
                  },
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={9.5}
                  sx={{
                    display: {
                      xs: "none",
                      md: "initial",
                    },
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        height: {
                          xs: "30vh",
                          md: "36vh",
                        },
                        overflow: "hidden",
                        paddingBottom: "50px",
                      }}
                    >
                      <Box
                        sx={{
                          width: {
                            xs: "100%",
                            md: "100%",
                            p: "00px 0 100px 0",
                          },
                        }}
                      >
                        <animated.div style={fade}>
                          <div ref={(el) => (sections.current[0] = el)}>
                            <Typography
                              sx={{
                                color:
                                  currentStep !== 0 ? "lightgrey" : "black",
                                opacity: currentStep !== 0 ? 0.5 : 1,
                              }}
                            >
                              Enter your name
                            </Typography>

                            <TextField
                              id="name"
                              name="name"
                              variant="filled"
                              margin="normal"
                              fullWidth
                              value={formik.values.name}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              disabled={currentStep !== 0} // Enable only the current step
                              error={
                                formik.touched.name &&
                                Boolean(formik.errors.name)
                              }
                              helperText={
                                formik.touched.name && formik.errors.name
                                  ? formik.errors.name
                                  : ""
                              }
                              sx={{
                                opacity: currentStep !== 0 ? 0.3 : 1,
                              }}
                            />
                          </div>
                          <div
                            ref={(el) => (sections.current[1] = el)}
                            style={{
                              marginTop: "40px",
                            }}
                          >
                            <Typography
                              sx={{
                                color:
                                  currentStep !== 1 ? "lightgrey" : "black",
                                opacity: currentStep !== 1 ? 0.5 : 1,
                              }}
                            >
                              Enter your company name
                            </Typography>
                            <TextField
                              sx={{
                                opacity: currentStep !== 1 ? 0.3 : 1,
                              }}
                              name="companyName"
                              id="companyName"
                              value={formik.values.companyName}
                              onChange={formik.handleChange}
                              fullWidth
                              variant="filled"
                              margin="normal"
                              disabled={currentStep !== 1} // Enable only the current step
                              error={
                                formik.touched.companyName &&
                                Boolean(formik.errors.companyName)
                              } // Display red border if error exists
                              helperText={
                                formik.touched.companyName &&
                                formik.errors.companyName
                                  ? formik.errors.companyName
                                  : ""
                              } // Show error message
                            />
                          </div>

                          <div
                            ref={(el) => (sections.current[2] = el)}
                            style={{
                              marginTop: "40px",
                            }}
                          >
                            <Typography
                              sx={{
                                color:
                                  currentStep !== 2 ? "lightgrey" : "black",
                                opacity: currentStep !== 2 ? 0.5 : 1,
                              }}
                            >
                              Select your industry type
                            </Typography>
                            <Autocomplete
                              sx={{
                                opacity: currentStep !== 2 ? 0.3 : 1,
                              }}
                              disableClearable={true}
                              options={industryOptions}
                              getOptionLabel={(option) => option.label}
                              id="industryType"
                              name="industryType"
                              value={
                                industryOptions.find(
                                  (option) =>
                                    option.value === formik.values.industryType
                                ) || null
                              }
                              onChange={(event, value) => {
                                formik.setFieldValue(
                                  "industryType",
                                  value ? value.value : ""
                                );
                              }}
                              fullWidth
                              disabled={currentStep !== 2} // Enable only the current step
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  {...params}
                                  variant="filled"
                                  margin="normal"
                                  error={
                                    formik.touched.industryType &&
                                    Boolean(formik.errors.industryType)
                                  }
                                  helperText={
                                    formik.touched.industryType &&
                                    formik.errors.industryType
                                      ? formik.errors.industryType
                                      : ""
                                  }
                                />
                              )}
                            />
                          </div>
                          <div
                            ref={(el) => (sections.current[4] = el)}
                            style={{
                              marginTop: "40px",
                            }}
                          >
                            <Typography
                              sx={{
                                color:
                                  currentStep !== 4 ? "lightgrey" : "black",
                                opacity: currentStep !== 4 ? 0 : 0,
                              }}
                            >
                              Select your industry type
                            </Typography>
                            <Autocomplete
                              sx={{
                                opacity: currentStep !== 4 ? 0 : 0,
                              }}
                              options={industryOptions}
                              getOptionLabel={(option) => option.label}
                              id="industryType"
                              name="industryType"
                              value={
                                industryOptions.find(
                                  (option) =>
                                    option.value === formik.values.industryType
                                ) || null
                              }
                              onChange={(event, value) => {
                                formik.setFieldValue(
                                  "industryType",
                                  value ? value.value : ""
                                );
                              }}
                              fullWidth
                              disabled={currentStep !== 3} // Enable only the current step
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="filled"
                                  margin="normal"
                                  error={
                                    formik.touched.industryType &&
                                    Boolean(formik.errors.industryType)
                                  }
                                  helperText={
                                    formik.touched.industryType &&
                                    formik.errors.industryType
                                      ? formik.errors.industryType
                                      : ""
                                  }
                                />
                              )}
                            />
                          </div>
                        </animated.div>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                {/* ////// for mobile device */}
                <Grid
                  item
                  xs={12}
                  md={9.5}
                  sx={{
                    display: {
                      xs: "initial",
                      md: "none",
                    },
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        height: {
                          xs: "30vh",
                          md: "36vh",
                        },
                        overflow: "hidden",
                      }}
                    >
                      <Box
                        sx={{
                          width: {
                            xs: "100%",
                            md: "50%",
                          },
                        }}
                      >
                        <animated.div style={fade}>
                          {/* countryName Step */}
                          {currentStep === 0 && (
                            <div
                              ref={(el) => (sections.current[0] = el)}
                              style={{ marginTop: "40px" }}
                            >
                              <Typography sx={{ color: "black" }}>
                                Enter your name
                              </Typography>
                              <TextField
                                name="name"
                                id="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                fullWidth
                                variant="filled"
                                margin="normal"
                                error={
                                  formik.touched.name &&
                                  Boolean(formik.errors.name)
                                }
                                helperText={
                                  formik.touched.name && formik.errors.name
                                }
                              />
                            </div>
                          )}

                          {/* Name Step */}
                          {currentStep === 1 && (
                            <div
                              ref={(el) => (sections.current[1] = el)}
                              style={{ marginTop: "40px" }}
                            >
                              <Typography sx={{ color: "black" }}>
                                Enter your company name
                              </Typography>
                              <TextField
                                name="companyName"
                                id="companyName"
                                value={formik.values.companyName}
                                onChange={formik.handleChange}
                                fullWidth
                                variant="filled"
                                margin="normal"
                                error={
                                  formik.touched.companyName &&
                                  Boolean(formik.errors.companyName)
                                }
                                helperText={
                                  formik.touched.companyName &&
                                  formik.errors.companyName
                                }
                              />
                            </div>
                          )}

                          {/* Company Name Step */}
                          {currentStep === 2 && (
                            <div
                              ref={(el) => (sections.current[2] = el)}
                              style={{ marginTop: "40px" }}
                            >
                              <Typography sx={{ color: "black" }}>
                                Select your industry type
                              </Typography>
                              <Autocomplete
                                options={industryOptions}
                                getOptionLabel={(option) => option.label}
                                id="industryType"
                                name="industryType"
                                value={
                                  industryOptions.find(
                                    (option) =>
                                      option.value ===
                                      formik.values.industryType
                                  ) || null
                                }
                                onChange={(event, value) => {
                                  formik.setFieldValue(
                                    "industryType",
                                    value ? value.value : ""
                                  );
                                }}
                                // fullWidth
                                renderInput={(params) => (
                                  <TextField
                                    fullWidth
                                    {...params}
                                    variant="filled"
                                    margin="normal"
                                    error={
                                      formik.touched.industryType &&
                                      Boolean(formik.errors.industryType)
                                    }
                                    helperText={
                                      formik.touched.industryType &&
                                      formik.errors.industryType
                                    }
                                  />
                                )}
                              />
                            </div>
                          )}
                        </animated.div>
                      </Box>
                    </Box>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={2}
                  sx={{
                    ml: {
                      xs: "0px",
                      md: "30px",
                    },
                    p: {
                      xs: "0 0 50px 0",
                      md: "0 0 0px 0",
                    },
                    display: {
                      xs: "flex",
                      md: "initial",
                    },
                  }}
                >
                  <Box
                    color="primary"
                    onClick={handleBack}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <img src={TopArrow} />
                  </Box>
                  <Button
                    variant="contained"
                    // color="primary"
                    type="submit"
                    onClick={() => {
                      handleNext();
                    }} // Call handleNext on click
                    sx={{
                      whiteSpace: "nowrap",
                      textTransform: "capitalize",
                      mt: {
                        xs: "0px",
                        md: "15px",
                      },
                      ml: {
                        xs: "30px",
                        md: "0",
                      },
                    }}
                  >
                    Save & go next
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Grid>
        <Grid
          item
          xs={12}
          md={2.5}
          sx={{
            background: "#1A5CB0",
            padding: "0 0px 50px 0",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.2rem",
              color: "white",
              fontWeight: 900,
              p: "100px 20px 40px 20px",
            }}
          >
            Quick & Easy Setup
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stepper
              activeStep={0}
              orientation="vertical"
              sx={{
                color: "white",
                "& .MuiStepLabel-label": {
                  color: "white",
                },
              }}
            >
              <Step>
                <StepLabel>
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "0.8rem",
                      ml: 1,
                      mb: 0, // Remove bottom margin
                    }}
                  >
                    Business Details
                  </Typography>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  <Typography
                    sx={{
                      color: "#6198DE",
                      fontSize: "0.8rem",
                      ml: 1,
                      mb: 0, // Remove bottom margin
                    }}
                  >
                    Define Frequently Ask Questions
                  </Typography>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  <Typography
                    sx={{
                      color: "#6198DE",
                      fontSize: "0.8rem",
                      ml: 1,
                      mb: 0, // Remove bottom margin
                    }}
                  >
                    Select Customer Care Number
                  </Typography>
                </StepLabel>
              </Step>
            </Stepper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MultiStepForm;
