import React, { useEffect, useState } from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import PhoneMissedIcon from "@mui/icons-material/PhoneMissed";

import axios from "axios";

const CardsWidgets = () => {
  const [widgetData, setWidgetData] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const uid = localStorage.getItem("uid");
  const token = localStorage.getItem("token"); // Get token from localStorage

  const getData = async () => {
    try {
      const res = await axios.get(
        `http://103.96.222.133/justtawk-uae/index.php/api/Onboarding/Dashboard/${uid}`,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setWidgetData(res.data.data.calls);
      console.log(res.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return (
    <Box sx={{ pt: "30px" }}>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: 2,
              bgcolor: "#626BE2",
              boxShadow: " rgba(0, 0, 0, 0.22) 0px 1px 1px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: 36,
                p: "0 20px",
              }}
            >
              <SmartphoneIcon style={{ color: "white" }} />
            </Box>
            <Box sx={{ p: "10px   0px" }}>
              <Typography
                sx={{ fontWeight: 550, color: "white", fontSize: "1rem" }}
              >
                Total Call
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "white" }}
              >
                {widgetData[0]?.total}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sm={6} md={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: 2,
              bgcolor: "#3CA5F4",
              boxShadow: " rgba(0, 0, 0, 0.22) 0px 1px 1px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: 36,
                p: "0 20px",
              }}
            >
              <PhoneInTalkIcon style={{ color: "white" }} />
            </Box>
            <Box sx={{ p: "10px   0px" }}>
              <Typography
                sx={{ fontWeight: 550, color: "white", fontSize: "1rem" }}
              >
                Call Answered
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "white" }}
              >
                {widgetData[0]?.Answered_Call}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sm={6} md={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: 2,
              bgcolor: "#F1526D",
              boxShadow: " rgba(0, 0, 0, 0.22) 0px 1px 1px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: 36,
                p: "0 20px",
              }}
            >
              <PhoneMissedIcon style={{ color: "white" }} />
            </Box>
            <Box sx={{ p: "10px   0px" }}>
              <Typography
                sx={{ fontWeight: 550, color: "white", fontSize: "1rem" }}
              >
                Missed Call
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "white" }}
              >
                {widgetData[0]?.Missed_Call}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CardsWidgets;
