import { Box, LinearProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Loading = () => {
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);
  useEffect(() => {
    setTimeout(() => {
      navigate("/qr")
    }, 2000);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Typography
        sx={{
          fontSize: "2rem",
        }}
      >
        Please wait we are setting up your call center ...
      </Typography>
      <Typography
        sx={{
          fontSize: "1rem",
          fontWeight: 100,
          color: "grey",
        }}
      >
        We are collecting your inputs to feed AI on future steps
      </Typography>
      <Box sx={{ width: "50%", mt: "50px" }}>
        <LinearProgress variant="indeterminate" value={progress} />
      </Box>
    </Box>
  );
};

export default Loading;
