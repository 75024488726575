import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoginBg from "../../../src/assets/LoginBg.jpeg";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function Otp() {
  const navigate = useNavigate();
  const uid = localStorage.getItem("uid");
  const isNew = localStorage.getItem("isNew");

  const varifytOtp = async (otp) => {
    try {
      const res = await axios.post(
        "http://103.96.222.133/justtawk-uae/index.php/api/User/otp",
        otp
      );
      console.log(res?.data?.status);
      if (res?.data?.status == true) {
        if (isNew == 2) {
          navigate("/dashboard");
        } else {
          navigate("/form");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .required("OTP is required")
        .matches(/^\d{5}$/, "Must be a valid 5-digit OTP"),
    }),
    onSubmit: (values) => {
      const data = {
        otp: values.otp,
        userid: uid,
      };
      console.log("Form values:", values);
      varifytOtp(data);
    },
  });

  return (
    <Container
      maxWidth={"xl"}
      sx={{ display: "flex", alignItems: "center", height: "100%" }}
    >
      <Grid container sx={{ height: "100vh", mt: 0 }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={4}
          sx={{
            backgroundImage: `url(${LoginBg})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) => t.palette.primary.main,
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            color: "white",
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: { xs: "0px 0", md: "100px 0" },
            borderRadius: "12px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center", // Center text vertically
              alignItems: "center", // Center text horizontally
              textAlign: "center", // Ensure text is centered within its Box
            }}
          >
            <Typography
              sx={{
                fontWeight: 900,
                fontSize: {
                  xs: "1.3rem",
                  md: "1.8rem",
                },
              }}
            >
              Welcome to Justtawk
            </Typography>
            <Typography
              sx={{
                fontWeight: 100,
                fontSize: {
                  xs: "0.8rem",
                  md: "1rem",
                },
              }}
            >
              Your Gateway to Effortless Call Center.
            </Typography>
          </Box>

          {/* Bottom Text Block */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center", // Center text vertically
              alignItems: "center", // Center text horizontally
              textAlign: "center", // Ensure text is centered within its Box
              mt: "auto", // Pushes the bottom block towards the bottom
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 900,
                fontSize: {
                  xs: "1.3rem",
                  md: "1.8rem",
                },
              }}
            >
              Seamless Collaboration
            </Typography>
            <Typography
              sx={{
                fontWeight: 100,
                fontSize: {
                  xs: "0.8rem",
                  md: "1rem",
                },
              }}
            >
              Effortlessly work together with your <br /> team in real-time.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          sx={{
            // p: { xs: "0 0px", md: "0 300px" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {/* Form Section */}
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  fontSize: "2rem",
                  fontWeight: 900,
                }}
              >
                OTP Verification
              </Typography>
            </Box>
            {/* OTP Field */}
            <Typography sx={{ fontSize: "0.8rem", color: "#26203B", mt: 4 }}>
              OTP has been sent to your email address. Please verify
            </Typography>
            <TextField
              id="otp"
              name="otp"
              type="text"
              variant="outlined"
              margin="normal"
              fullWidth
              value={formik.values.otp}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.otp && Boolean(formik.errors.otp)}
              helperText={formik.touched.otp && formik.errors.otp}
              InputProps={{
                style: {
                  height: "40px",
                  borderRadius: "8px",
                  border: "1px solid #465FF166",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                },
              }}
            />
            <Box sx={{ mt: 1 }}>
              <Typography variant="caption" color="textSecondary" gutterBottom>
                OTP Requirements
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                display="block"
              >
                • Must be a valid 6-digit OTP
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                display="block"
              >
                • Cannot contain any letters or special characters (e.g., +, -,
                or spaces)
              </Typography>
            </Box>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{
                mt: 3,
                mb: 2,
                bgcolor: "#465FF1",
                textTransform: "capitalize",
              }}
            >
              Verify OTP
            </Button>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
}
