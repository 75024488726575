import React, { useState } from "react";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { animate } from "framer-motion";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const validationSchema = Yup.object({
  question: Yup.string().required("Question is required"),
  answer: Yup.string().required("Answer is required"),
});

const AddFaq = ({ getFaq }) => {
  const [open, setOpen] = useState(false);
  const uuid = localStorage.getItem("uid");
  const token = localStorage.getItem("token");
  
  const formik = useFormik({
    initialValues: {
      question: "",
      answer: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = {
        [values.question]: values.answer,
      };
      addFaqDetails(data);
    },
  });

  const addFaqDetails = async (data) => {
    const access_token = token;

    try {
      const response = await axios.post(
        `http://103.96.222.133/justtawk-uae/index.php/api/Onboarding/Faq/${uuid}`,
        data,
        {
          headers: {
            Authorization: `${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Response:", response.data);
      formik.resetForm();
      getFaq();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Box sx={{ p: "0px 0 20px 0" }}>
        <Button variant="contained" onClick={handleOpen}>
          Add Faq's
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="add-faq-modal-title"
          aria-describedby="add-faq-modal-description"
        >
          <Box sx={style}>
            <Typography id="add-faq-modal-title" variant="h6" component="h2">
              Add New FAQ
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                label="Question"
                name="question"
                fullWidth
                variant="outlined"
                margin="normal"
                value={formik.values.question}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.question && Boolean(formik.errors.question)
                }
                helperText={formik.touched.question && formik.errors.question}
                required
              />
              <TextField
                label="Answer"
                name="answer"
                fullWidth
                variant="outlined"
                margin="normal"
                multiline
                rows={4}
                value={formik.values.answer}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.answer && Boolean(formik.errors.answer)}
                helperText={formik.touched.answer && formik.errors.answer}
                required
              />
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Button variant="outlined" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary" type="submit">
                  Save
                </Button>
              </Box>
            </form>
          </Box>
        </Modal>
      </Box>
    </div>
  );
};

export default AddFaq;
