import { createBrowserRouter } from "react-router-dom";
import Layout from "../layout";
import MainForm from "../view/form";
import Number from "../view/form/section/Number";
import Loading from "../view/form/section/Loading";
import Qr from "../view/form/section/Qr";
import Faq from "../view/faq";
import DashBoard from "../view/dashboard";
import MultiStepForm from "../view/form/section/MultiStepForm";
import Login from "../view/login/Login";
import Otp from "../view/login/Otp";
import SaaS from "../view/form/faq/saas/Saas";
import PricingPlans from "../view/pricing";
import AdminDashboard from "../view/adminDashboard";
import AdminLogin from "../view/login/AdminLogin";
import ECommerce from "../view/form/faq/ECommerce/ECommerce";
import Education from "../view/form/faq/Education/Education";
import HealthCare from "../view/form/faq/HealthCare/HealthCare";
import Software from "../view/form/faq/Software/Software";
import Startup from "../view/form/faq/Startup/Startup";
import Telecommunications from "../view/form/faq/Telecommunications/Telecommunications";
import Travel from "../view/form/faq/Travel/Travel";
import CallLogs from "../view/callLogs";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/form",
    element: <MultiStepForm />,
  },
  {
    path: "/number",
    element: <Number />,
  },
  {
    path: "/loading",
    element: <Loading />,
  },
  {
    path: "/qr",
    element: <Qr />,
  },
  {
    path: "/saas",
    element: <SaaS />,
  },
  {
    path: "/ECommerce",
    element: <ECommerce />,
  },
  {
    path: "/Education",
    element: <Education />,
  },
  {
    path: "/HealthCare",
    element: <HealthCare />,
  },
  {
    path: "/Software",
    element: <Software />,
  },
  {
    path: "/Startup",
    element: <Startup />,
  },
  {
    path: "/Telecommunications",
    element: <Telecommunications />,
  },
  {
    path: "/Travel",
    element: <Travel />,
  },
  {
    path: "/dashboard",
    element: <DashBoard />,
  },
  {
    path: "/admin-dashboard",
    element: <AdminDashboard />,
  },
  {
    path: "/faq",
    element: <Faq />,
  },
  {
    path: "/Pricing",
    element: <PricingPlans />,
  },
  {
    path: "/Call-Log",
    element: <CallLogs />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/admin-login",
    element: <AdminLogin />,
  },
  {
    path: "/otp",
    element: <Otp />,
  },
]);

export default router;
