import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Container } from "@mui/material";
import { QRCodeSVG } from "qrcode.react"; // Import the QRCode component
import { useNavigate } from "react-router-dom";
import Confetti from "react-confetti";

// Custom hook to handle window size
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

export default function Qr() {
  const navigate = useNavigate();
  const callCenterNumber = localStorage.getItem("selectedPhoneAlias");

  const { width, height } = useWindowSize();

  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 4300);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Container maxWidth="lg">
      {showConfetti && <Confetti width={width} height={height} />}
      <Box sx={{ my: 5, textAlign: "center" }}>
        <Typography
          sx={{
            fontSize: "3rem",
            fontWeight: 900,
            fontFamily: "serif",
          }}
          component="h1"
        >
          Congratulation! Your Call Center is ready
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            mb: 4,
            fontSize: "1.4rem",
            fontWeight: 100,
            color: "grey",
            p: "0 200px",
            lineHeight: "26px",
            mt: 1,
          }}
        >
          Call on your customer care number and enjoy an exceptional experience
          tailored to your needs.
        </Typography>
        {/* Generate the QR code dynamically based on the call center number */}
        <Box
          sx={{
            m: "50px 0",
          }}
        >
          <QRCodeSVG value={callCenterNumber} size={200} />
        </Box>
        <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
          Your Call Center number: {callCenterNumber}
        </Typography>
        <Typography
          variant="body2"
          sx={{ mb: 4, color: "grey", fontWeight: 100 }}
        >
          Scan the QR code with your mobile to connect with your call center,
          <br />
          or dial the customer care number above.
        </Typography>
        <Button
          variant="contained"
          size="large"
          sx={{
            textTransform: "capitalize",
          }}
          onClick={() => navigate("/dashboard")}
        >
          Go to Dashboard
        </Button>
      </Box>
    </Container>
  );
}
