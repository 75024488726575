import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoginImg from "../../../src/assets/login.png";
import Logo from "../../../src/assets/logoBlue.jpeg";
import LoginBg from "../../../src/assets/LoginBg.jpeg";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function Login() {
  const navigate = useNavigate();

  const registerUser = async (mobile) => {
    try {
      const response = await axios.post(
        "http://103.96.222.133/justtawk-uae/index.php/api/User/register",
        { mobile } // Send mobile number instead of email
      );
      localStorage.setItem("token", response?.data?.access_token);
      localStorage.setItem("uid", response?.data?.uid);
      localStorage.setItem("isNew", response?.data?.isNew);

      navigate("/otp");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      mobile: "",
    },
    validationSchema: Yup.object({
      mobile: Yup.string()
        .required("Mobile number is required")
        .matches(/^[0-9]{10}$/, "Must be a valid 10-digit mobile number"), // Mobile number validation (10 digits)
    }),
    onSubmit: (values) => {
      console.log("Form values:", values);
      registerUser(values.mobile); // Send mobile number for registration
    },
  });

  return (
    <Container
      maxWidth={"xl"}
      sx={{
        display: "flex",
        alignItems: "center",
        height: "100%",
        flexDirection: "column",
      }}
    >
      <Grid container sx={{ height: "100vh", mt: 0 }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={4}
          sx={{
            backgroundImage: `url(${LoginBg})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) => t.palette.primary.main,
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            color: "white",
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: { xs: "0px 0", md: "100px 0" },
            borderRadius: "12px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center", // Center text vertically
              alignItems: "center", // Center text horizontally
              textAlign: "center", // Ensure text is centered within its Box
            }}
          >
            <Typography
              sx={{
                fontWeight: 900,
                fontSize: {
                  xs: "1.3rem",
                  md: "1.8rem",
                },
              }}
            >
              Welcome to Justtawk
            </Typography>
            <Typography
              sx={{
                fontWeight: 100,
                fontSize: {
                  xs: "0.8rem",
                  md: "1rem",
                },
              }}
            >
              Your Gateway to Effortless Call Center.
            </Typography>
          </Box>

          {/* Bottom Text Block */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center", // Center text vertically
              alignItems: "center", // Center text horizontally
              textAlign: "center", // Ensure text is centered within its Box
              mt: "auto", // Pushes the bottom block towards the bottom
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 900,
                fontSize: {
                  xs: "1.3rem",
                  md: "1.8rem",
                },
              }}
            >
              Seamless Collaboration
            </Typography>
            <Typography
              sx={{
                fontWeight: 100,
                fontSize: {
                  xs: "0.8rem",
                  md: "1rem",
                },
              }}
            >
              Effortlessly work together with your <br /> team in real-time.
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          sx={{
            p: { xs: "0 30px", md: "0 300px" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {" "}
          {/* Form Section */}
          <form onSubmit={formik.handleSubmit}>
            <Box>
              <Typography
                sx={{
                  fontSize: "2rem",
                  fontWeight: 900,
                }}
              >
                Login
              </Typography>
              <Typography
                sx={{
                  fontSize: "1rem",
                }}
              >
                Your Gateway to Effortless Call Center
              </Typography>
            </Box>
            <Typography sx={{ fontSize: "0.8rem", color: "#26203B", mt: 4 }}>
              Please enter your mobile number*
            </Typography>
            <TextField
              id="mobile"
              name="mobile" // Ensure this matches with formik initialValues
              type="tel"
              variant="outlined"
              margin="normal"
              fullWidth
              value={formik.values.mobile}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.mobile && Boolean(formik.errors.mobile)}
              helperText={formik.touched.mobile && formik.errors.mobile}
              InputProps={{
                style: {
                  height: "40px",
                  borderRadius: "8px",
                  border: "1px solid #465FF166",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                },
              }}
            />
            <Box sx={{ mt: 1 }}>
              <Typography variant="caption" color="textSecondary" gutterBottom>
                Mobile Number Requirements
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                display="block"
              >
                • Must be a valid 10-digit mobile number
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                display="block"
              >
                • Cannot contain any letters or special characters (e.g., +, -,
                or spaces)
              </Typography>
            </Box>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{
                mt: 3,
                mb: 2,
                bgcolor: "#465FF1",
                textTransform: "capitalize",
              }}
            >
              Login
            </Button>
            <Grid container>
              <Grid item xs>
                {/* <Link href="#" variant="body2">
                    Forgot password?
                  </Link> */}
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
}
