import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoginBg from "../../../src/assets/LoginBg.jpeg";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function AdminLogin() {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "", // Add initial value for password
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email address is required")
        .email("Must be a valid email address"),
      password: Yup.string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters long"), // Password validation
      // .matches(
      //   /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{6,}$/,
      //   "Password must contain at least one letter and one number"
      // ),
    }),
    onSubmit: (values) => {
      console.log("Form values:", values);
      navigate("/admin-dashboard");
      // You can handle login logic here, like calling the API
    },
  });

  return (
    <Container
      maxWidth={"xl"}
      sx={{
        display: "flex",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Grid container sx={{ height: "100vh", mt: 0 }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={4}
          sx={{
            backgroundImage: `url(${LoginBg})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) => t.palette.primary.main,
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            color: "white",
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: { xs: "0px 0", md: "100px 0" },
            borderRadius: "12px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center", // Center text vertically
              alignItems: "center", // Center text horizontally
              textAlign: "center", // Ensure text is centered within its Box
            }}
          >
            <Typography
              sx={{
                fontWeight: 900,
                fontSize: {
                  xs: "1.3rem",
                  md: "1.8rem",
                },
              }}
            >
              Welcome to Justtawk
            </Typography>
            <Typography
              sx={{
                fontWeight: 100,
                fontSize: {
                  xs: "0.8rem",
                  md: "1rem",
                },
              }}
            >
              Your Gateway to Effortless Call Center.
            </Typography>
          </Box>

          {/* Bottom Text Block */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center", // Center text vertically
              alignItems: "center", // Center text horizontally
              textAlign: "center", // Ensure text is centered within its Box
              mt: "auto", // Pushes the bottom block towards the bottom
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 900,
                fontSize: {
                  xs: "1.3rem",
                  md: "1.8rem",
                },
              }}
            >
              Seamless Collaboration
            </Typography>
            <Typography
              sx={{
                fontWeight: 100,
                fontSize: {
                  xs: "0.8rem",
                  md: "1rem",
                },
              }}
            >
              Effortlessly work together with your <br /> team in real-time.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          sx={{
            p: { xs: "0 30px", md: "0 300px" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {/* Form Section */}
          <form onSubmit={formik.handleSubmit}>
            <Box>
              {/* <Box
                sx={{
                  height: "7vh",
                }}
              >
                <img src={Logo} alt="Logo" height="100%" width="150" />{" "}
              </Box> */}
              <Typography
                sx={{
                  fontSize: "2rem",
                  fontWeight: 900,
                }}
              >
                Admin Login ✋
              </Typography>
              <Typography
                sx={{
                  fontSize: "1rem",
                }}
              >
                Your Gateway to Effortless Call Center
              </Typography>
            </Box>
            <Typography sx={{ fontSize: "0.8rem", color: "#26203B", mt: 4 }}>
              Please enter your email address*
            </Typography>
            <TextField
              id="email"
              name="email"
              type="email"
              variant="outlined"
              margin="normal"
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              InputProps={{
                style: {
                  height: "40px",
                  borderRadius: "8px",
                  border: "1px solid #465FF166",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                },
              }}
            />

            <Typography sx={{ fontSize: "0.8rem", color: "#26203B", mt: 2 }}>
              Please enter your password*
            </Typography>
            <TextField
              id="password"
              name="password"
              type="password" // Password type
              variant="outlined"
              margin="normal"
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                style: {
                  height: "40px",
                  borderRadius: "8px",
                  border: "1px solid #465FF166",
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                },
              }}
            />
            <Box sx={{ mt: 1 }}>
              <Typography variant="caption" color="textSecondary" gutterBottom>
                Password Requirements
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                display="block"
              >
                • Must be at least 6 characters long
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                display="block"
              >
                • Cannot contain any letters or special characters (e.g., +, -,
                or spaces)
              </Typography>
            </Box>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{
                mt: 3,
                mb: 2,
                bgcolor: "#465FF1",
                textTransform: "capitalize",
              }}
            >
              Login
            </Button>
            <Grid container>
              <Grid item xs>
                {/* Forgot password link */}
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
}
